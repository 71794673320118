import "./Header.css";
import logo from "../../img/logo.svg";
import React from "react";
import iconNav from "../../img/icon-nav.svg";
import { Link } from "react-router-dom";

export default function Header() {

  return (
    <header className="header-section">
      <Link className="con-logo" to="/">
        <img src={logo} alt="logo-big-barden" />
      </Link>
      <nav className="nav-bar">
        <div className="cont-bar" >
          <img src={iconNav} alt="icon-nav-bar" />
        </div>
      </nav>
    </header>
  );
}
