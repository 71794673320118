import React, { useRef } from "react";
import "./HomePage.css";
import Header from "../../components/Header/Header";
import Hero from "../../components/Hero/Hero";
import Slider from "../../components/Slider/Slider";
import Recent from "../../components/Recent/Recent";
import Footer from "../../components/Footer/Footer";
import arrowfoot from "../../img/icon-arrow.svg";
import { Link } from "react-router-dom";

export default function Home() {
  const containerRef = useRef(null);

  const scrollToTop = () => {
    containerRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <section ref={containerRef} className="app-general">
      <section className="home-welcome-section">
        <Header />
        <Hero />
        <Slider />
      </section>
      <Recent />
      <button onClick={scrollToTop} className="btn-up">
        <img src={arrowfoot} alt="icon-foot" />
      </button>
      <section className="cont-politicas">
        <button>
          <Link className="link-politics" to="/politicas-de-privacidad">Política de privacidad</Link>
        </button>
        <button>
          <Link className="link-politics" to="/politica-de-cookies">Uso de cookies</Link>
        </button>
      </section>
      <Footer />
    </section>
  );
}
