import "./DetailCategories.css";
import useFirestoreCollection from "../../useFirestoreCollection";
import time from "../../img/time-icon.svg";
import { Link } from "react-router-dom";
import Loading from "../Loading/Loading.jsx";


export default function DetailCategories({ categoryName }) {
  const { data, loading, error } = useFirestoreCollection("articles");
  if (loading) {
    return <div className="space-loading"><Loading/></div>;
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  const filteredArticles = data.filter(item => item.categoria === categoryName);

  return (
    <div className="cont-articles-detail-cat">
      <section className="cont-all-articles">
        {filteredArticles.map((item) => (
          <Link to={`/detalle-articulo/${item.id}`} key={item.id} className="article-item-body">
            <section className="info-article-item">
              <h3>{item.name}</h3>
              <div className="cont-title-article">
                <h4>{item.categoria}</h4>
                <div className="cont-date">
                  <img src={time} alt="icon-time" />
                  <p>{item.date}</p>
                </div>
              </div>
            </section>
            <img className="bg-article-item" src={item.portada} alt="icon-time" />
          </Link>
        ))}
      </section>
    </div>
  );
}
