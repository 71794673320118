import "./PrivacidadPage.css";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";

export default function PrivacidadPage() {
  return (
    <section className="cont-info-cookies">
      <Header />
      <div className="info-cookies">
        <h4>Política de Privacidad Bigbarden.com</h4>
        <h5>Fecha de última actualización: 08/01/2024</h5>
        <p>
          Gracias por visitar Bigbarden.com. Tu privacidad es
          importante para nosotros. Esta política de privacidad explica cómo
          recopilamos, usamos, compartimos y protegemos tu información personal.
          Información que Recopilamos: Información Personal: Cuando te registras
          en nuestro sitio web, solicitamos información personal como tu nombre,
          dirección de correo electrónico, y otra información relevante.
          Información de Navegación: Recopilamos automáticamente información
          sobre tu visita, incluyendo la dirección IP, el tipo de navegador, la
          duración de la visita y las páginas visitadas. Uso de la Información:
          Utilizamos la información recopilada para: Personalizar tu experiencia
          en el sitio. Enviar actualizaciones y comunicaciones relacionadas con
          el contenido de nuestro sitio web. Mejorar nuestro sitio web y
          servicios. Protección de la Información: Tomamos medidas para proteger
          tu información personal y asegurar que no sea accesible por terceros
          no autorizados. Sin embargo, no podemos garantizar la seguridad
          absoluta de la información transmitida a través de Internet. Cookies:
          Utilizamos cookies para mejorar la experiencia del usuario. Puedes
          configurar tu navegador para rechazar todas las cookies o para indicar
          cuando se envía una cookie. Divulgación de la Información: No
          compartiremos tu información personal con terceros sin tu
          consentimiento, excepto cuando sea requerido por ley. Enlaces a Otros
          Sitios Web: Nuestro sitio web puede contener enlaces a otros sitios.
          No somos responsables de las prácticas de privacidad de estos sitios y
          te recomendamos revisar sus políticas de privacidad. Cambios en la
          Política de Privacidad: Nos reservamos el derecho de actualizar
          nuestra política de privacidad. Cualquier cambio será publicado en
          esta página y entrará en vigencia inmediatamente. Contacto: Si tienes
          preguntas o inquietudes sobre nuestra política de privacidad,
          contáctanos en contacto@bigbarden.com. Gracias por
          confiar en bigbarden.com.
        </p>
      </div>
      <Footer />
    </section>
  );
}
