import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home/HomePage.jsx";
import CategoriaPage from "./pages/Categoria/CategoriaPage.jsx";
import DetailArticlePage from "./pages/DetailArticle/DetailArticlePage.jsx";
import CookiesPage from "./pages/Cookies/CookiesPage.jsx";
import PrivacidadPage from "./pages/Privacidad/PrivacidadPage.jsx";

export default function Router() {
    return (
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/detalle-categoria/:id" element={<CategoriaPage/>} />
        <Route path="/detalle-articulo/:id" element={<DetailArticlePage/>} />
        <Route path="/politica-de-cookies" element={<CookiesPage/>} />
        <Route path="/politicas-de-privacidad" element={<PrivacidadPage/>} />
      </Routes>
    );
  }