import "./Hero.css";
import bgHome from "../../img/noticia-futuro.jpg";
import bgHomePc from "../../img/noticia-pc.jpg";
import iconWhite from "../../img/icon-arrow-white.svg";

export default function Hero() {
  return (
    <section className="hero-section">
      <div className="hero-h1-cont">
        <h1>Consejos ecológicos para un estilo de vida ecoamigable</h1>
      </div>
      <div className="hero-scroll">
        <p>Scroll</p>
        <img src={iconWhite} alt="icon-arrow" />
      </div>
      <div className="hero-img">
        <img className="hero-vid" src={bgHome} alt="imagen-fondo-home" />
        <img className="hero-vid-two" src={bgHomePc} alt="imagen-fondo-home" />
      </div>
    </section>
  );
}
