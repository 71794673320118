
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCzPVXTJ_RNVJojBu9I4dF8Qtt_856JPZo",
  authDomain: "big-barden.firebaseapp.com",
  projectId: "big-barden",
  storageBucket: "big-barden.appspot.com",
  messagingSenderId: "312318392294",
  appId: "1:312318392294:web:97e307d0634b9643ef0827",
  measurementId: "G-4E4TH3VD2X"
};


const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);

export { db };