import "./DetailArticlePage.css";
import React, { useRef } from "react";
import { useParams } from "react-router-dom";
import useFirestoreCollection from "../../useFirestoreCollection";
import iconArrow from "../../img/icon-arrow.svg";
import iconLogo from "../../img/icon-logo.svg";
import time from "../../img/time-icon.svg";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import arrowfoot from "../../img/icon-arrow.svg";
import Loading from "../../components/Loading/Loading.jsx";



export default function DetailArticlePage() {
  const { id } = useParams();
  const sectionOne = useRef(null);
  const sectionTwo = useRef(null);
  const sectionTre = useRef(null);
  const containerRef = useRef(null);

  const { data, loading, error } = useFirestoreCollection("articles");

  if (loading) {
    return <div className="space-loading"><Loading/></div>;
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  const selectedCategory = data.find((item) => item.id === id);

  const scrollToSection = (index) => {
    const section = [sectionOne, sectionTwo, sectionTre, containerRef][index];
    if (section && section.current) {
      section.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleSectionClick = (index) => {
    scrollToSection(index);
  };

  return (
    <section ref={containerRef} className="cont-detail-article-info">
      <section className="cont-title-article-detail">
        <div className="info-section-article">
          <Link className="link-out" to={`/`}>
            <img className="img-section-out" src={iconArrow} alt="icon arrow" />
          </Link>
          <img className="img-icon-logo" src={iconLogo} alt="icon-logo" />
        </div>
        <img
          className="bg-article-portada"
          src={selectedCategory?.portada}
          alt="portada-articulo"
        />
      </section>
      <section className="section-info-article-detail">
        <div className="cont-info-general-article">
          <h2>{selectedCategory?.name}</h2>
          <div className="cont-detail-article-date">
            <p>{selectedCategory?.categoria}</p>
            <div className="item-box-time">
              <img src={time} alt="icon-time" />
              <p>{selectedCategory?.date}</p>
            </div>
          </div>
        </div>
        <div className="cont-titles-generals-article">
          <p>Accesos rápidos:</p>
          <section className="cont-titles-sections">
            <h3 onClick={() => handleSectionClick(0)}>
              {selectedCategory?.sectionOne?.titleGeneral}
            </h3>
            <h3 onClick={() => handleSectionClick(1)}>
              {selectedCategory?.sectionTwo?.titleGeneral}
            </h3>
            <h3 onClick={() => handleSectionClick(2)}>
              {selectedCategory?.sectionTree?.titleGeneral}
            </h3>
          </section>
        </div>
        <div className="part-one-article">
          <p>{selectedCategory?.introduccion}</p>
        </div>
        <div ref={sectionOne} className="part-two-article">
          <h3>{selectedCategory?.sectionOne?.titleGeneral}</h3>
          <p>{selectedCategory?.sectionOne?.infoGeneral}</p>
          <img
            src={selectedCategory?.sectionOne?.imgSection}
            alt="imagen-articulo"
          />
          <section className="box-items-part-two">
            <div className="item-part-two">
              <h4>{selectedCategory?.sectionOne?.subtitleFirst}</h4>
              <p>{selectedCategory?.sectionOne?.textFirst}</p>
            </div>
            <div className="item-part-two">
              <h4>{selectedCategory?.sectionOne?.subtitleSecond}</h4>
              <p>{selectedCategory?.sectionOne?.textSecond}</p>
            </div>
            <div className="item-part-two">
              <h4>{selectedCategory?.sectionOne?.subtitleTercer}</h4>
              <p>{selectedCategory?.sectionOne?.textTercer}</p>
            </div>
          </section>
        </div>
        <div ref={sectionTwo} className="part-two-article">
          <h3>{selectedCategory?.sectionTwo?.titleGeneral}</h3>
          <p>{selectedCategory?.sectionTwo?.infoGeneral}</p>
          <img
            src={selectedCategory?.sectionTwo?.imgSection}
            alt="imagen-articulo"
          />
          <section className="box-items-part-two">
            <div className="item-part-two">
              <h4>{selectedCategory?.sectionTwo?.subtitleFirst}</h4>
              <p>{selectedCategory?.sectionTwo?.textFirst}</p>
            </div>
            <div className="item-part-two">
              <h4>{selectedCategory?.sectionTwo?.subtitleSecond}</h4>
              <p>{selectedCategory?.sectionTwo?.textSecond}</p>
            </div>
            <div className="item-part-two">
              <h4>{selectedCategory?.sectionTwo?.subtitleTercer}</h4>
              <p>{selectedCategory?.sectionTwo?.textTercer}</p>
            </div>
          </section>
        </div>
        <div ref={sectionTre} className="part-two-article">
          <h3>{selectedCategory?.sectionTree?.titleGeneral}</h3>
          <p>{selectedCategory?.sectionTree?.infoGeneral}</p>
          <img
            src={selectedCategory?.sectionTree?.imgSection}
            alt="imagen-articulo"
          />
          <section className="box-items-part-two">
            <div className="item-part-two">
              <h4>{selectedCategory?.sectionTree?.subtitleFirst}</h4>
              <p>{selectedCategory?.sectionTree?.textFirst}</p>
            </div>
            <div className="item-part-two">
              <h4>{selectedCategory?.sectionTree?.subtitleSecond}</h4>
              <p>{selectedCategory?.sectionTree?.textSecond}</p>
            </div>
            <div className="item-part-two">
              <h4>{selectedCategory?.sectionTree?.subtitleTercer}</h4>
              <p>{selectedCategory?.sectionTree?.textTercer}</p>
            </div>
          </section>
        </div>
        <div className="conclusion-article">
          <p>{selectedCategory?.conclusion}</p>
        </div>
      </section>
      <button onClick={() => handleSectionClick(3)} className="btn-up">
        <img src={arrowfoot} alt="icon-foot" />
      </button>
      <Footer />
    </section>
  );
}
