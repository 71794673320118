import "./Footer.css";
import logoFoot from "../../img/logo-white.svg";


export default function Footer() {
  return (
    <footer className="foot-sec">
      <img src={logoFoot} alt="logo-bigbarden-footer" />
      <section className="cont-info-foot">
        <h4>Todos podemos construir un futuro ecoamigable</h4>
        <p>Copyright 2023 - Walkeriano.</p>
      </section>
    </footer>
  );
}
