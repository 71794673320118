import "./Slider.css";
import React, { useRef } from "react";
import useFirestoreCollection from '../../useFirestoreCollection';
import { Link } from "react-router-dom";
import Loading from "../Loading/Loading.jsx";
import arrowSlider from "../../img/icon-arrow.svg";

export default function Slider() {
    const { data, loading, error } = useFirestoreCollection('categories');
    let container = useRef(null);

    if (loading) {
      return <div className="space-loading"><Loading/></div>;
    }
  
    if (error) {
      return <p>Error: {error.message}</p>;
    }

    const left = () => {
      if (container.current) {
        container.current.scrollLeft -= 400;
      }
    };
  
    const right = () => {
      if (container.current) {
        container.current.scrollLeft += 400;
      }
    };

  return (
    <section className="slider-section">
      <div onClick={left} className="cont-btn-slider-left">
        <img src={arrowSlider} alt="icon-arrow" />
      </div>
      <section ref={container} className="slider-items">
        {data.map((item) => (
          <Link key={item.id} to={`/detalle-categoria/${item.id}`} className="item-link" >
            <div className="cont-cat-tittle">
              <h2>{item.name}</h2>
            </div>
            <div className="cont-icon-cat">
                <img src={item.iconCat} alt="icon-categoria" />
            </div>
            <img className="portada-cat" src={item.portada} alt="imagen-categoria" />
          </Link>
        ))}
      </section>
      <div onClick={right} className="cont-btn-slider">
        <img src={arrowSlider} alt="icon-arrow" />
      </div>
    </section>
  );
}
