import "./CookiesPage.css";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";


export default function CookiesPage() {
  return (
    <section className="cont-info-cookies">
      <Header />
      <div className="info-cookies">
        <h4>
          Política de Cookies Bigbarden.com
        </h4>
        <h5>Fecha de última actualización: 08/01/2024</h5>
        <p>
          Bienvenido/a a Bigbarden.com. Esta Política de Cookies tiene como
          objetivo informarte de manera clara y transparente sobre el uso de
          cookies y tecnologías similares cuando visitas nuestro sitio web. 1.
          ¿Qué son las cookies? Las cookies son pequeños archivos de texto que
          se almacenan en tu dispositivo (ordenador, tableta, teléfono móvil)
          cuando visitas un sitio web. Estas cookies contienen información sobre
          tu navegación en el sitio y se utilizan para mejorar tu experiencia de
          usuario. 2. Tipos de cookies que utilizamos: Cookies esenciales: Son
          cookies necesarias para el funcionamiento básico de nuestro sitio web.
          Permiten la navegación y el acceso a áreas seguras del sitio. Cookies
          de rendimiento: Nos ayudan a entender cómo interactúas con nuestro
          sitio web, recopilando información sobre las páginas que visitas y
          cualquier error que puedas encontrar. Estas cookies no recopilan
          información que identifique al visitante; toda la información es
          anónima. Cookies de funcionalidad: Permiten recordar tus preferencias
          y elecciones (como el idioma) para proporcionar una experiencia más
          personalizada. Cookies de publicidad: Son utilizadas para entregarte
          anuncios más relevantes para ti y tus intereses. También se utilizan
          para limitar la cantidad de veces que ves un anuncio y ayudan a medir
          la efectividad de las campañas publicitarias. 3. Control de cookies:
          Puedes gestionar las cookies a través de la configuración de tu
          navegador. Ten en cuenta que deshabilitar ciertas cookies puede
          afectar la funcionalidad de nuestro sitio web. Google Chrome:
          Instrucciones Mozilla Firefox: Instrucciones Microsoft Edge:
          Instrucciones 4. Cookies de terceros: Algunas cookies pueden ser
          colocadas por terceros que aparecen en nuestro sitio web. Estos
          terceros están sujetos a sus propias políticas de privacidad y
          cookies. 5. Cambios en la política de cookies: Nos reservamos el
          derecho de realizar cambios en esta Política de Cookies en cualquier
          momento. La versión más reciente estará siempre disponible en nuestro
          sitio web. Al utilizar nuestro sitio web, aceptas el uso de cookies de
          acuerdo con esta Política. Si no estás de acuerdo con el uso de
          cookies, por favor, deshabilita las cookies siguiendo las
          instrucciones proporcionadas anteriormente o deja de usar nuestro
          sitio web. Si tienes alguna pregunta sobre nuestra Política de
          Cookies, no dudes en ponerte en contacto con nosotros a través de
          contacto@bigbarden.com. Gracias por visitar Bigbarden.com
        </p>
      </div>
      <Footer/>
    </section>
  );
}
