import React, { useRef } from "react";
import { useParams } from "react-router-dom";
import useFirestoreCollection from "../../useFirestoreCollection";
import "./CategoriaPage.css";
import Header from "../../components/Header/Header";
import arrow from "../../img/icon-white.svg";
import DetailCategories from "../../components/DetailCategories/DetailCategories";
import Loading from "../../components/Loading/Loading.jsx";
import Footer from "../../components/Footer/Footer";
import arrowfoot from "../../img/icon-arrow.svg";

export default function CategoriaPage() {
  const { id } = useParams();
  const containerRef = useRef(null);
  const { data, loading, error } = useFirestoreCollection("categories");

  if (loading) {
    return (
      <div className="space-loading-home">
        <Loading />
      </div>
    );
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  const selectedCategory = data.find((item) => item.id === id);

  const categoryName = selectedCategory?.name;
  const numberOfArticles = data.length;

  const scrollToTop = () => {
    containerRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <section ref={containerRef} className="cont-general-detail-categorie">
      <Header />
      {selectedCategory && (
        <section className="cont-detail-categorie">
          <div className="img-detail-categorie">
            <h2>{selectedCategory?.name}</h2>
          </div>
          <div className="cont-extra-detail-categorie">
            <div className="item-icon-contain">
              <img src={selectedCategory.iconCat} alt="icon-categoria" />
            </div>
            <div className="item-article-lenght">
              <p>Artículos:</p>
              <p>{numberOfArticles}</p>
              <img src={arrow} alt="arrow-flecha" />
            </div>
          </div>
          <img
            className="bg-detail-article"
            src={selectedCategory.portada}
            alt=""
          />
        </section>
      )}
      <DetailCategories categoryName={categoryName} />
      <button onClick={scrollToTop} className="btn-up">
        <img src={arrowfoot} alt="icon-foot" />
      </button>
      <Footer />
    </section>
  );
}
